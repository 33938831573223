

























































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Component from 'vue-class-component';
import camelcaseKeys from 'camelcase-keys';

import { toasts } from '@/utils/toasts';
import { constants } from '../../../utils/constants';

import ResetPasswordModal from './ResetPasswordModal.vue';

import {
  getUser,
  setDocumentSeries,
  getDocumentSeries,
  getInventoryAccess,
  setInventoryAccess,
  saveProfile,
} from './user-management-api';

@Component({
  components: {
    ResetPasswordModal,
  },
  computed: {
    ...mapGetters(['canAdminister']),
  },
})
export default class extends Vue {
  tableBusy = false;
  item: any = null;

  selectedGrnSeries: Array<any> = [];
  selectedMrnSeries: Array<any> = [];
  selectedIarnSeries: Array<any> = [];
  selectedIatnSeries: Array<any> = [];

  grnSeries: Array<any> = [];
  mrnSeries: Array<any> = [];
  iarnSeries: Array<any> = [];
  iatnSeries: Array<any> = [];

  selectedInventories: Array<any> = [];
  inventories: Array<any> = [];

  editUsername: string = '';
  editFullName: string = '';
  editEmailAddress: string = '';
  editIsActive: boolean = true;
  editUserType: string = constants.userAccountTypes.user;

  editNewPassword: string = '';
  editConfirmPassword: string = '';

  isProfileCurrentUser: boolean = true;

  userTypeOptions: Array<any> = [
    { value: constants.userAccountTypes.admin, text: 'Administrator' },
    { value: constants.userAccountTypes.user, text: 'Utilizator' },
  ];

  clearRestrictions(series, ref) {
    series.splice(0);
    (<any>(this.$refs[ref])).deactivate();
  }

  getLatestUsedDocumentSeriesIndex(option) {
    return option.startIndex !== option.nextIndex
      ? option.nextIndex - 1
      : option.startIndex;
  }

  async saveProfile() {
    try {
      await saveProfile(this.item.webAppUserGuid, {
        username: this.editUsername,
        fullName: this.editFullName,
        emailAddress: this.editEmailAddress,
        isActive: this.editIsActive,
        userType: this.editUserType,
      });

      toasts.success('Profilul a fost salvat.');
      this.$router.push({ name: 'ApplicationSettings.UserManagement.Details', params: { userGuid: this.$route.params.userGuid } });
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    } finally {
      await this.loadGridPage();
    }
  }

  async resetPassword() {
    (<any>(this.$refs.resetPasswordModal)).showModal();
  }

  async onMrnSeriesRestrictionChange() {
    try {
      await setDocumentSeries(this.$route.params.userGuid, this.selectedMrnSeries.map((x) => x.documentSeriesId), constants.MRN);
    } catch (err) {
      toasts.error('Eroare la salvarea restricțiilor.');
    }
  }

  async onGrnSeriesRestrictionChange() {
    try {
      await setDocumentSeries(this.$route.params.userGuid, this.selectedGrnSeries.map((x) => x.documentSeriesId), constants.GRN);
    } catch (err) {
      toasts.error('Eroare la salvarea restricțiilor.');
    }
  }

  async onIarnSeriesRestrictionChange() {
    try {
      await setDocumentSeries(this.$route.params.userGuid, this.selectedIarnSeries.map((x) => x.documentSeriesId), constants.BCO);
    } catch (err) {
      toasts.error('Eroare la salvarea restricțiilor.');
    }
  }

  async onIatnSeriesRestrictionChange() {
    try {
      await setDocumentSeries(this.$route.params.userGuid, this.selectedIatnSeries.map((x) => x.documentSeriesId), constants.BTO);
    } catch (err) {
      toasts.error('Eroare la salvarea restricțiilor.');
    }
  }

  async onInventoriesChange() {
    try {
      await setInventoryAccess(this.$route.params.userGuid, this.selectedInventories.map((x) => x.inventoryId));
    } catch (err) {
      toasts.error('Eroare la editarea gestiunilor.');
    }
  }

  async loadGridPage() {
    try {
      this.tableBusy = true;

      this.item = camelcaseKeys(await getUser(this.$route.params.userGuid));

      this.editUsername = this.item.username;
      this.editFullName = this.item.fullName;
      this.editEmailAddress = this.item.emailAddress;
      this.editIsActive = this.item.isActive;
      this.editUserType = this.item.tip;

      this.isProfileCurrentUser = this.$store.state.userGuid === this.$route.params.userGuid;

      const grnSeries = camelcaseKeys(await getDocumentSeries(this.$route.params.userGuid, constants.GRN));
      const mrnSeries = camelcaseKeys(await getDocumentSeries(this.$route.params.userGuid, constants.MRN));
      const iarnSeriesResult = camelcaseKeys(await getDocumentSeries(this.$route.params.userGuid, constants.BCO));
      const iatnSeriesResult = camelcaseKeys(await getDocumentSeries(this.$route.params.userGuid, constants.BTO));

      this.inventories = camelcaseKeys(await getInventoryAccess(this.$route.params.userGuid));
      this.selectedInventories = this.inventories.filter((x) => x.isUserAssociated);

      this.selectedGrnSeries = grnSeries.restrictedSeries;
      this.grnSeries = grnSeries.allSeries;

      this.selectedMrnSeries = mrnSeries.restrictedSeries;
      this.mrnSeries = mrnSeries.allSeries;

      this.selectedIarnSeries = iarnSeriesResult.restrictedSeries;
      this.iarnSeries = iarnSeriesResult.allSeries;

      this.selectedIatnSeries = iatnSeriesResult.restrictedSeries;
      this.iatnSeries = iatnSeriesResult.allSeries;
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
      return;
    } finally {
      this.tableBusy = false;
    }
  }

  async initialize() {
    await this.loadGridPage();
  }

  created() {
    if (!this.$store.getters.canAdminister) {
      this.$router.replace({ name: 'Dashboard' });
      return;
    }
    this.initialize();
  }
}
