











































































import Vue from 'vue';
import Component from 'vue-class-component';
import { toasts } from '@/utils/toasts';

import {
  resetPassword,
} from './user-management-api';

@Component
export default class extends Vue {
  newPassword = null;
  retypedNewPassword = null;

  async onPasswordChangeModalOk(event) {
    event.preventDefault();
    try {
      if (!this.newPassword || !this.retypedNewPassword) {
        toasts.error('Completati toate campurile');
        return;
      }
      if (this.newPassword !== this.retypedNewPassword) {
        toasts.error('Parolele introduse nu se potrivesc');
        return;
      }

      const resetModel = {
        webAppUserGuid: this.$route.params.userGuid,
        newPassword: this.newPassword,
        repeatNewPassword: this.retypedNewPassword,
      };

      await resetPassword(resetModel);
      toasts.success('Parola a fost schimbată cu succes');
      (<any>(this.$refs.resetPasswordModal)).hide();
    } catch (err) {
      toasts.error(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  showModal() {
    (<any>(this.$refs.resetPasswordModal)).show();
    this.initializeData();
  }

  initializeData() {
    this.newPassword = null;
    this.retypedNewPassword = null;
  }
}

